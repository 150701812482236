.trophy-panel {
	/* source size 300x700px + light bleed */
	--tpw: calc(min(720px,100vw) - 50px - 2rem);
	--tw: calc(var(--tpw) * 0.18);
	--th: calc(700 / 300 * var(--tw));
	--zs: calc(32 / 300 * var(--tw));
	grid-area: trophy-panel;
	width: var(--tpw);
	display: grid;
	grid-template-columns: repeat(5,var(--tw));
	grid-template-rows: var(--th);
	grid-gap: 0 2.5%;
	margin: 0 auto;
	/*background-color: #00FF0020;*/
}