@import url('https://fonts.googleapis.com/css?family=Roboto|Roboto+Condensed|Roboto+Mono|Teko:300|Teko:400|Anton&display=swap');

:root {
	font-size: 12px;
	font-family: 'Roboto', sans-serif;
	margin: 0;
	padding: 0;
	overflow-y: scroll;
}
:root * {
	margin: 0;
	padding: 0;
}

#container {
	color: white;
	background-color: black;
	background-image: linear-gradient(to left, #000000E8 calc(50% - 360px), #000000C8, #000000E8 calc(50% + 360px)),
	                  url("../img/podsel-bg-mono.jpg");
	background-size: cover;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: center;
	min-height: 100vh;
}


h1 {
	margin: 1rem 0 0 !important;
	font-size: 2.25rem;
	text-transform: uppercase;
	text-align: center;
	color: #888;
	grid-area: h1;
	font-family: 'Teko', 'Roboto', sans-serif;
}
h1:first-child {
	margin-top: 0 !important;
}
h2 {
	margin: -0.2em 0 1rem !important;
	font-size: 3.75rem;
	line-height: 0.85em;
	text-transform: uppercase;
	text-align: center;
	grid-area: h2;
	font-family: 'Teko', 'Roboto', sans-serif;
}
h3 {
	margin: -0.9em 0 0.5em !important;
	font-size: 1.9rem;
	grid-area: h3;
	border-bottom: 1px solid #888;
	padding-bottom: 0.25em !important;
	font-family: 'Teko', 'Roboto', sans-serif;
	letter-spacing: 0.04em;
	line-height: 1.1em;
}
h4 {
	font-size: 1.9rem;
	grid-area: h4;
	padding-bottom: 0.35em !important;
	font-family: 'Teko', 'Roboto', sans-serif;
	letter-spacing: 0.04em;
	line-height: 1.1em;
}


nav {
	flex-grow: 1;
	grid-area: nav;
	padding: 0 0.25em !important;
	user-select: none;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
nav a, nav button {
	display: inline-block;
	margin-left: 0.25em !important;
	text-transform: uppercase;
	padding: 0.35em 0.5em !important;
	font-family: 'Roboto', sans-serif;
	border: none;
	background: transparent;
}
nav a:hover, nav button:hover {
	background: rgba(127,127,255,0.1);
	font-weight: bold;
	cursor: pointer;
}


main {
	max-width: min(100vw,720px);
	margin: 0 auto !important;
	padding: 1rem 1rem 4rem !important;
	display: grid;
	grid-template-columns: repeat(9,1fr);
	grid-template-rows: auto auto 1fr;
	grid-gap: 0 1.5rem;
	--panel-text-shadow: 1px 1px 4px #000;
	background: inherit;
	box-sizing: border-box;
}

main.ranking, main.track, main.track-list, main.player-list {
	grid-template-areas:
		'h1 h1 h1 h1 h1 h1 h1 h1 h1'
		'h2 h2 h2 h2 h2 h2 h2 h2 h2'
		'hl-panel hl-panel hl-panel hl-panel hl-panel hl-panel hl-panel hl-panel hl-panel'
		'table table table table table table table table table';
}

main.player {
	grid-template-areas:
		'h2 h2 h2 h2 h2 h2 h2 h2 h2'
		'trophy-panel trophy-panel trophy-panel trophy-panel trophy-panel trophy-panel trophy-panel trophy-panel trophy-panel'
		'h1 h1 h1 h1 h1 h1 h1 h1 h1'
		'hl-panel hl-panel hl-panel hl-panel hl-panel hl-panel hl-panel hl-panel hl-panel'
		'table table table table table table table table table';
}
main.player h1 { margin: 2.5rem 0 0.5rem !important; color: #FFF; }
main.player h2 { margin-top: 0.1em !important; }

main.about, main.changelog, main.error-message, main.developer {
	display: block;
	font-size: 1.25rem;
}
main button {
	display: inline-block;
	text-transform: uppercase;
	padding: 0.15em 0.35em !important;
	margin: 0 0.15em !important;
	font-family: 'Roboto', sans-serif;
	font-size: 1.15em;
	border: none;
	color: #8080FF;
	background: transparent;
}
main button:hover {
	background: rgba(127,127,255,0.1);
	font-weight: bold;
	cursor: pointer;
}
main li {
	margin-left: 1em !important;
}

p, ul {
	margin-bottom: 1.35em !important;
	line-height: 1.35em;
	text-align: justify;
	list-style-type: square;
}
ul > ul {
	margin-bottom: 0.25em !important;
	margin-left: 1.15em !important;
}

a {
	color: #8080FF;
	text-decoration: none;
}
a:hover {
	color: #A0A0FF;
}


@import url('category-string.css');
@import url('format-numbers.css');


.load-screen {
	height: 100vh;
}


@media all and (max-width: 720px) {
	#container {
		background-image: linear-gradient(to left, #000000E8, #000000C8, #000000E8),
		                  url("../img/podsel-bg-mono.jpg");
	}
}


@media all and (max-width: 480px) {
	nav {
		justify-content: center;
	}

	main {
		grid-gap: 0 1rem;
		grid-template-areas:
			'h1 h1 h1 h1 h1 h1 h1 h1 h1'
			'h2 h2 h2 h2 h2 h2 h2 h2 h2'
			'table table table table table table table table table'
			'panel panel panel panel panel panel panel panel panel';
	}

	h1 {
		font-size: 1.75rem;
	}
	h2 {
		font-size: 3rem;
	}
	h3, h4 {
		font-size: 1.5rem;
	}

	p, ul {
		font-size: 1.1rem;
	}

	nav a {
		margin: 0 !important;
	}
}