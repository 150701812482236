.highlight-item {
	width: var(--hlw);
	height: var(--hlh);
	position: relative;
	margin: 0 0 0.75em;
	display: inline-block;
	/*background-color: #00FF0020;*/
}
.highlight-item .value {
	font-family: 'Anton', 'Impact', sans-serif;
	font-size: calc(1.5 / 8 * var(--hlw));
	width: calc(var(--hlw) / 2);
	height: calc(var(--hlw) / 2);
	position: absolute;
	top: 0;
	left: calc(var(--hlw) / 4);
}
.highlight-item .value:before {
	content: '';
	background-image: radial-gradient(#FFFFFF00, #FFFFFF60);
	position: absolute;
	top: 7.5%;
	left: 7.5%;
	width: 85%;
	height: 85%;
	border-radius: 50%;
	border: 1px solid #FFF;
	box-sizing: border-box;
	opacity: 0.5;
	transition: opacity ease 0.35s;
}
.highlight-item:hover .value:before { opacity: 1; }
.highlight-item .value:after {
	content: '';
	background-image: radial-gradient(#0000FF00, #0000FF60);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border: 2px solid #8080FF;
	box-sizing: border-box;
	transform: translateZ(calc(var(--hlw) / -8));
	filter: drop-shadow(0 0 calc(1.35 / 8 * var(--hlw)) #00F);
}
.highlight-item .value .Tilt-inner {
	position: absolute;
	top: calc(50% - 0.65em);
	left: calc(var(--hlw) / -4);
	width: var(--hlw);
	text-align: center;
	transform: translateZ(calc(var(--hlw) / 8));
	pointer-events: none;
	background: -webkit-linear-gradient(#FFFFFF, #C0C0FF);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	filter: drop-shadow(0 0 calc(1.35 / 8 * var(--hlw)) #0000FF);
}
.highlight-item .name {
	font-family: 'Roboto Condensed', sans-serif;
	position: absolute;
	bottom: 0;
	left: 0;
	width: var(--hlw);
	text-align: center;
	font-size: calc(1.15 / 8 * var(--hlw));
	color: #C0C0FF;
	text-shadow: 0 0 calc(1.35 / 8 * var(--hlw)) #0000FF;
	font-weight: bold;
	text-transform: uppercase;
}