.trophy-container {
	width: var(--tw);
	height: var(--th);
}
.trophy {
	width: var(--tw);
	height: var(--th);
	box-sizing: border-box;
	position: relative;
	cursor: pointer;
}
.trophy:before, .trophy:after {
	content: '';
	position: absolute;
	top: calc(-40 / 700 * var(--th));
	left: calc(-50 / 300 * var(--tw));
	width: calc(400 / 300 * var(--tw));
	height: var(--th);
	background-image: url("../img/trophy-gen-bg1.png");
	background-size: 100%;
	display: block;
	pointer-events: none;
}
.trophy:after {
	background-image: url("../img/trophy-gen-bg2.png");
	opacity: 0.5;
	transition: opacity ease 0.35s;
}
.trophy:hover:after {
	opacity: 1;
}
.trophy.rank-1:before {	background-image: url("../img/trophy-r1-bg1.png"); }
.trophy.rank-1:after {	background-image: url("../img/trophy-r1-bg2.png"); }
.trophy.rank-2:before {	background-image: url("../img/trophy-r2-bg1.png"); }
.trophy.rank-2:after {	background-image: url("../img/trophy-r2-bg2.png"); }
.trophy.rank-3:before {	background-image: url("../img/trophy-r3-bg1.png"); }
.trophy.rank-3:after {	background-image: url("../img/trophy-r3-bg2.png"); }
.trophy.rank-1:after, .trophy.rank-2:after, .trophy.rank-3:after { transform: translateZ(calc(var(--zs) * -1)); }
.trophy:not(.rank-1):not(.rank-2):not(.rank-3):before { transform: translateZ(calc(var(--zs) * -1)); }
.trophy.rank-0 > *, .trophy.rank-0:before, .trophy.rank-0:after { opacity: 0.25; }
.trophy.rank-1 .trophy-rank, .trophy.rank-2 .trophy-rank, .trophy.rank-3 .trophy-rank {
	position: absolute;
	top: calc(60 / 700 * var(--th));
	left: calc(55 / 300 * var(--tw));
	width: calc(190 / 300 * var(--tw));
	height: calc(400 / 700 * var(--th));
	background-size: 100%;
	text-indent: -1000vw;
	transform: translateZ(var(--zs));
}
.trophy.rank-1 .trophy-rank { background-image: url("../img/trophy-r1.png"); }
.trophy.rank-2 .trophy-rank { background-image: url("../img/trophy-r2.png"); }
.trophy.rank-3 .trophy-rank { background-image: url("../img/trophy-r3.png"); }
.trophy:not(.rank-1):not(.rank-2):not(.rank-3) .trophy-rank {
	font-family: 'Anton', 'Impact', sans-serif;
	font-style: italic;
	font-size: calc(180 / 300 * var(--tw));
	text-align: center;
	position: absolute;
	top: calc(140 / 700 * var(--th));
	left: calc(calc(-25 / 300 * var(--tw)) + -0.075em);
	width: calc(350 / 300 * var(--tw));
	height: calc(280 / 700 * var(--th));
	background: -webkit-linear-gradient(#FFF, #8080FF);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	transform: scale(0.75, 1) translateZ(var(--zs));
}
.trophy:not(.rank-1):not(.rank-2):not(.rank-3) .trophy-rank:before, .trophy:not(.rank-1):not(.rank-2):not(.rank-3) .trophy-rank:after {
	/*background-color: #400000;*/
	content: '◆';
	font-family: 'Roboto Condensed', sans-serif;
	font-style: normal;
	position: absolute;
	top: -1em;
	left: calc(calc(var(--tw) - var(--tw) * 0.75) / 2);
	width: var(--tw);
	font-size: 0.15em;
	text-align: center;
	-webkit-text-fill-color: #B8B8FF;
	text-shadow: 0 0 calc(32 / 300 * var(--tw)) #00F;
	transform: scale(calc(1 / 0.75), calc(1 / 0.75)) translateZ(calc(var(--zs) * -1));
	pointer-events: none;
	font-weight: bold;
}
.trophy:not(.rank-1):not(.rank-2):not(.rank-3) .trophy-rank:after {
	content: 'RANK';
	top: auto;
	bottom: -1em;
	transform: scale(calc(1 / 0.75), 1) translateZ(calc(var(--zs) * -1));
}
.trophy.rank-1 .trophy-points, .trophy.rank-2 .trophy-points, .trophy.rank-3 .trophy-points {
	font-family: 'Anton', 'Impact', sans-serif;
	font-size: calc(44 / 300 * var(--tw));
	font-style: italic;
	text-align: center;
	text-indent: -0.5em;
	position: absolute;
	top: calc(466 / 700 * var(--th));
	left: calc(26 / 300 * var(--tw));
	width: calc(248 / 300 * var(--tw));
	height: calc(72 / 700 * var(--th));
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-size: 100%;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.trophy.rank-1 .trophy-points { background-image: url("../img/trophy-r1-points.png"); }
.trophy.rank-2 .trophy-points { background-image: url("../img/trophy-r2-points.png"); }
.trophy.rank-3 .trophy-points { background-image: url("../img/trophy-r3-points.png"); }
.trophy:not(.rank-1):not(.rank-2):not(.rank-3) .trophy-points {
	font-family: 'Roboto Mono', sans-serif;
	font-size: calc(32 / 300 * var(--tw));
	text-align: center;
	position: absolute;
	top: calc(560 / 700 * var(--th) + 0.75em);
	width: var(--tw);
	text-shadow: 0 0 calc(32 / 300 * var(--tw)) #00F;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-weight: bold;
}
.trophy-points:before {
	content: '✦';
	font-size: 0.75em;
	margin: 0 0.35em 0 0;
}
.trophy.rank-1 .trophy-points:before, .trophy.rank-2 .trophy-points:before, .trophy.rank-3 .trophy-points:before {
	margin: 0;
	font-style: normal;
	font-weight: bold;
}
.trophy-cat {
	font-family: 'Roboto Condensed', sans-serif;
	font-size: calc(48 / 300 * var(--tw));
	text-align: center;
	position: absolute;
	top: calc(var(--th) - 1.1em);
	left: 0;
	width: var(--tw);
	text-shadow: 0 0 calc(32 / 300 * var(--tw)) #00F;
	color: #B8B8FF;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	transform: translateZ(calc(var(--zs) * -1));
}
.trophy-cat svg {
	height: 1.1em;
	width: 1.1em;
	margin: 0 0.1em;
	fill: #FFF;
}
.trophy-cat svg.cat-on {
	fill: #B8B8FF;
	filter: drop-shadow(0 0 calc(16 / 300 * var(--tw)) #B8B8FF); /*#B8FFB8*/
}
.trophy-cat svg.cat-off {
	fill: #404040;
	filter: drop-shadow(0 0 calc(16 / 300 * var(--tw)) #404040); /*#FFB8B8*/
}